// 2020 Redesign
// Base library for shared styles for the new sd product pages.
// Lib is attached per template as needed.

@import '../theme-bootstrap';

.sd-product-spp {
  // Afterpay message hide in SPP by SKU
  &[data-sku-base-id='145608'] {
    .elc-product-installments-price {
      visibility: hidden;
      margin-top: 0;
    }
  }
}

.elc-vto-foundation-overlay {
  .elc-checkbox {
    display: flex;
    padding: 0 25px;
  }
  .elc-checkbox-icon {
    border: 2px solid $color-white;
    background-color: transparent;
    height: 15px;
    width: 15px;
    margin-top: 5px;
    position: relative;
    -webkit-mask: none;
    &::after {
      border-radius: 5px;
      color: $color-white;
      content: "";
      font-size: 15px;
      #{$ldirection}: 0;
      line-height: 0;
      position: absolute;
      top: 6px;
    }
  }
  .elc-checkbox-selected .elc-checkbox-icon::after {
    content: "\2715";
  }
}

.elc-product-shade-grid-selected-image {
  border-radius: 100%;
}
.elc-product-shade-picker-dropdown-shade-icon,
.elc-product-shade-picker-dropdown-shade-hex,
.elc-product-shade-picker-dropdown-shade-image,
.elc-single-shade-image {
  border-radius: 100%;
  width: 22px;
  height: 22px;
}

// Quick View
.elc-product-quick-view-wrapper {
  max-width: 920px;
  .elc-product-quick-view-close-button {
    background-color: unset;
  }
  .elc-carousel-thumbnails {
    .elc-img {
      height: auto;
    }
    .elc-play-icon-wrapper {
      display: flex;
      width: 36px;
      height: 36px;
      border-width: 3px;
      .elc-play-icon {
        transform: scale(1);
        cursor: pointer;
        #{$ldirection}: 2px;
      }
    }
    &:hover {
      .elc-play-icon-wrapper {
        opacity: 1;
      }
    }
  }
  .elc-video-thumbnail-wrapper {
    background-color: transparent;
  }
  .elc-play-icon-wrapper {
    background-color: transparent;
    border: 3px solid $color-white;
    opacity: 0.8;
    transition: opacity 300ms;
    width: 90px;
    height: 90px;
    .elc-play-icon {
      transform: scale(2.8);
      position: relative;
      #{$ldirection}: 4px;
    }
  }
  .elc-play-icon {
    background-color: $color-white;
  }
  &:hover {
    .elc-play-icon-wrapper {
      opacity: 1;
    }
  }
  .elc-image-slider-images-wrapper {
    .elc-video-wrapper {
      padding-top: 100%;
    }
  }
  .elc-product-quick-view-details-wrapper, // remove selector on MPPFIND-3943
  .elc-right-column-section {
    display: flex;
    padding-bottom: 120px;
    margin: 0;
    width: 45%;
    flex-direction: column;
    align-self: flex-start;
  }
  .elc-product-quick-view-content {
    color: #040a2b;
    padding: 0 30px 30px;
    margin: 0;
    width: 100%;
    align-items: normal;
    display: flex;
    flex-direction: row;
  }
  .elc-carousel-section-wrapper {
    margin: 0;
    width: 100%;
  }
  .elc-left-column-section {
    margin: 0;
    width: 100%;
    @media #{$medium-only} {
      width: 50%;
      [dir='rtl'] & {
        margin: 0;
      }
    }
    @media (min-width: #{$medium-max}) {
      width: 55%;
      [dir='rtl'] & {
        margin: 0;
      }
    }// remove selector on MPPFIND-3943
  }

  .elc-carousel-section-wrapper,
  .elc-left-column-section {
    display: flex;
    .elc-carousel-thumbnails {
      width: 68px;
      max-height: 500px;
      > div {
        padding: 0;
      }
      .slick-current {
        .elc-img {
          border: 1px solid $color-navy;
        }
      }
      .elc-img {
        width: 60px;
        height: 60px;
      }
      .elc-slider-arrow-wrapper {
        #{$rdirection}: calc(50% - 16px);
        #{$ldirection}: auto;
        &.slick-prev,
        &.slick-next {
          transform: unset;
          width: 32px;
        }
        &.slick-prev {
          #{$ldirection}: 19px;
          top: -19px;
        }
        &.slick-next {
          #{$ldirection}: 18px;
          bottom: -25px;
        }
      }
      .elc-directional-icon {
        @include svg-icon-inline-mask('arrow-up.svg');
        transform: unset;
        width: 32px;
        height: 32px;
        display: block;
        &.elc-slider-arrow-bottom {
          transform: rotate(180deg);
        }
      }
    }
    .elc-carousel-products {
      width: 400px;
      @media #{$medium-only} {
        width: 300px;
      }
      .elc-slider-arrow-wrapper {
        width: 18px;
        height: 39px;
        z-index: 1;
        &.slick-prev {
          #{$ldirection}: 10px;
          [dir='rtl'] & {
            #{$rdirection}: 10px;
            #{$ldirection}: auto;
          }
        }
        &.slick-next {
          #{$rdirection}: 10px;
          [dir='rtl'] & {
            #{$rdirection}: auto;
            #{$ldirection}: 10px;
          }
        }
        &:before {
          content: unset;
        }
      }
      .elc-icon:not(.elc-play-icon) {
        position: absolute;
        transform: unset;
        width: 34px;
        height: 34px;
        display: block;
      }
      .elc-slider-arrow-left {
        @include svg-icon-inline-mask('arrow-left.svg');
      }
      .elc-slider-arrow-right {
        @include svg-icon-inline-mask('arrow-right.svg');
        #{$rdirection}: 0;
      }
    }
  }
  .elc-shade-image-wrapper {
    .elc-shade-image {
      border: none;
      background: none;
      padding: 0;
      height: 23px;
      width: 23px;
      margin: 4px;
      &.elc-status-temporarily-out-of-stock,
      &.elc-status-sold-out {
        &::after {
          font-size: 31px;
        }
        &.elc-shade-image-selected::after {
          font-size: 24px;
        }
      }
    }
    .elc-shade-image-selected {
      .elc-absolute-shade-wrapper::after {
        height: 21px;
        width: 21px;
      }
      &::after {
        height: 21px;
        width: 21px;
      }
    }
  }
  .elc-product-shade-groups {
    border-bottom: 1px solid $color-light-gray;
    margin: 7px 0;
    padding: 5px 0;
    @media #{$medium-up} {
      margin: 7px 10px;
    }
    .elc-slider-view-wrapper {
      padding: 0;
    }
    .slick-arrow {
      display: none;
    }
    .slick-slider {
      margin: 0;
    }
    .slick-slide {
      &:last-child {
        .elc-product-shade-group-link {
          margin: 0;
        }
      }
    }
  }
  .elc-product-shades-container {
    &::-webkit-scrollbar {
      width: 12px;
      background-color: $color-lightest-gray;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: $color-lightest-gray;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $color-medium-gray;
      border: 3px solid $color-lightest-gray;
    }
  }
  .elc-product-shade-group-link {
    border: none;
    background: none;
    margin-#{$rdirection}: 30px;
    text-transform: uppercase;
    padding: 0;
    line-height: 1.5;
    color: $color-navy;
    @media #{$medium-up} {
      margin-#{$rdirection}: 25px;
    }
    &.active {
      font-weight: bold;
    }
  }
}

.elc-carousel-thumbnails {
  .elc-slider-arrow-wrapper {
    .elc-product-quick-view-wrapper .elc-left-column-section & {
      margin-top: -8px;
      width: 18px;
      height: 39px;
      &.slick-prev {
        top: -25px;
        transform: unset;
        #{$ldirection}: 17px;
      }
      &.slick-next {
        bottom: -35px;
        transform: unset;
        #{$ldirection}: 17px;
      }
      &:before {
        content: unset;
      }
    }
  }
}

.elc-product-quick-view-details-wrapper, // remove selector on MPPFIND-3943
.elc-product-quick-view-wrapper .elc-right-column-section {
  @include swap_direction(padding, 0 0 80px 30px);
  margin: 0;
  .elc-product-info-wrapper {
    order: 1;
  }
  .elc-product-display-name,
  .elc-product-subheader,
  .elc-product-display-name-link {
    font-family: $optimabook;
    font-size: 26px;
    color: $color-navy;
    font-weight: normal;
    -webkit-font-smoothing: inherit;
    letter-spacing: inherit;
    letter-spacing: 0.21px;
    margin: 0;
    line-height: 32px;
    &:hover {
      text-decoration: none;
    }
  }
  .elc-product-subheader {
    margin-top: 5px;
  }
  .elc-product-learn-more-link {
    font-family: $akzidenz;
    font-size: 12px;
    text-transform: uppercase;
    position: absolute;
    bottom: 60px;
    #{$ldirection}: 57.5%;
    font-weight: bold;
    word-spacing: 3px;
    color: $color-navy;
    letter-spacing: 0.1px;
    line-height: 16px;
    @include swap_direction(margin, 0 0 0 4px);
    text-decoration: underline;
  }
  .elc-product-rating-wrapper {
    order: 2;
    display: block;
    margin: 5px 0 3px;
  }
  .elc-average-rating-text,
  .elc-read-reviews {
    display: none;
  }
  .elc-product-description-wrapper,
  .elc-product-short-description-wrapper {
    font-family: $akzidenz;
    font-size: 15px;
    line-height: 1.8;
    color: $color-navy;
  }
  .elc-product-short-description-wrapper {
    margin: 8px 0 0;
  }
  .elc-product-size-picker-wrapper {
    color: $color-navy;
    order: 4;
    ~ .elc-quickview-content-prices-wrapper .elc-product-price-wrapper {
      display: none;
    }
    .elc-dropdown-readonly-input {
      font-weight: bold;
    }
  }
  .elc-shades-dropdown-component {
    width: 100%;
    margin-bottom: 10px;
    .elc-shade-info-below {
      padding-top: 10px;
      padding-#{$ldirection}: 0;
      .elc-product-shade-name {
        display: none;
      }
      .elc-shade-description-inline {
        color: $color-navy;
        font-family: $akzidenz;
        text-transform: capitalize;
        font-size: 15px;
      }
    }
  }
  .elc-product-shade-picker {
    margin-bottom: 16px;
    .elc-product-shades-grid-wrapper {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      max-height: 94px;
      margin-#{$ldirection}: 0;
    }
    .slick-track {
      min-width: 320px;
    }
  }
  .elc-select-a-size {
    display: none;
  }
  .elc-product-shade-picker-wrapper {
    order: 3;
  }
  .elc-shade-image-selected {
    &::after {
      content: '';
      box-shadow: inset 0 0 0 2px $color-white;
    }
  }
  .elc-dropdown {
    .elc-dropdown-wrapper,
    .elc-dropdown-input-wrapper {
      height: 40px;
      width: 328px;
      border: 1px solid $color-navy;
    }
  }
  .elc-price-and-size,
  .elc-dropdown-readonly-input {
    font-family: $akzidenz;
    color: $color-navy;
    font-size: 15px;
    text-transform: uppercase;
    display: inline;
    width: 100%;
  }
  .elc-price-and-size {
    display: flex;
    font-weight: 700;
    .elc-formatted-price {
      margin-#{$rdirection}: 5px;
    }
  }
  .elc-dropdown-arrow {
    border: unset;
    .elc-arrow-icon {
      width: 8px;
      height: 11px;
    }
  }
  .elc-dropdown-options {
    width: 328px;
  }
  .elc-dropdown-option {
    font-family: $akzidenz;
    color: $color-navy;
    font-size: 15px;
    text-transform: uppercase;
    .elc-price-and-size {
      font-weight: 100;
      &:hover {
        background-color: $color-lightest-gray;
      }
    }
  }
  .elc-product-shade-picker-dropdown {
    margin: 10px 0 20px;
  }
  .elc-shades-dropdown-component {
    .elc-product-shade-picker-dropdown {
      margin: 10px 0 5px;
    }
  }
  .elc-select-a-size,
  .elc-quantity-selector-label,
  .elc-size-picker-label p {
    font-family: $akzidenz;
    font-size: 15px;
    padding: 0;
    color: $color-navy;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .elc-spinner-selector-data {
    color: $color-navy;
    font-family: inherit;
    text-align: center;
  }
  .elc-quantity-selector-label {
    font-weight: bold;
    text-transform: uppercase;
  }
  .elc-spinner-selector {
    border-radius: 0;
    border: 1px solid $color-navy;
    width: 135px;
    &-button {
      width: 30%;
      background: transparent;
      .elc-minus-icon,
      .elc-plus-icon {
        height: 13px;
        width: 13px;
        margin-#{$ldirection}: 20%;
      }
    }
  }
  .elc-spinner-selector-data-wrapper {
    padding: 9px 16px;
    border-#{$ldirection}: 1px solid $color-navy;
    border-#{$rdirection}: 1px solid $color-navy;
  }
  .elc-size-picker-label {
    justify-content: #{$ldirection};
  }

  .elc-quickview-content-quantity-auto-replenishment-wrapper, // remove selector on MPPFIND-3943
  .elc-quantity-selector-button {
    padding-bottom: 15px;
  }

  .elc-quickview-content-quantity-auto-replenishment-wrapper, // remove selector on MPPFIND-3943
  .elc-product-auto-replenish {
    margin-top: 13px;
    display: inline-grid;
  }
  // remove rule on MPPFIND-3943
  .elc-quickview-content-quantity-auto-replenishment-wrapper {
    order: 7;
    margin: 0;
  }

  // remove rule on MPPFIND-3943
  .elc-product-quantity-wrapper {
    order: 2;
  }
  .elc-quantity-selector-button {
    margin-#{$rdirection}: 150px;
    margin-top: 10px;
    align-self: flex-start;
  }
  // remove rule on MPPFIND-3943
  .elc-quick-view-auto-replenish-wrapper {
    order: 1;
  }
  .elc-quick-view-auto-replenish-wrapper, //remove selector on MPPFIND-3943
  .elc-product-auto-replenish {
    width: 100%;
    position: relative;
    .elc-auto-replenish {
      &-subscribe-wrapper {
        width: 53%;
        p {
          color: $color-navy;
          font-family: $akzidenz;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 1.5px;
          text-transform: uppercase;
        }
        .elc-switch-background {
          background: $color-navy;
        }
        .elc-switch-circle,
        .elc-switch {
          border: none;
        }
      }
      &-subscribe-copy-wrapper {
        font-size: 15px;
        margin-top: 5px;
        color: $color-navy;
        line-height: 1;
      }
      &-dropdown-wrapper {
        margin-bottom: 20px;
        > div {
          width: 328px;
        }
        .elc-dropdown {
          margin: 15px 0 0;
          transition: height 0.25s ease-in;
          &-disabled {
            height: 0;
            margin: 0;
            transition: height 0.25s ease-in;
            visibility: hidden;
          }
        }
        .elc-auto-replenish-open-modal {
          background: no-repeat;
          padding: 0;
          position: absolute;
          top: 4px;
          #{$ldirection}: 100px;
          .elc-info-icon {
            height: 15px;
            width: 15px;
          }
        }
      }
    }
  }
  .quickview-content-price-cta-wrapper {
    order: 8;
    position: relative;
    font-family: $akzidenz;
    font-size: 15px;
  }
  .elc-add-to-bag-button,
  .elc-product-notify-me-button {
    min-height: 40px;
    width: 135px;
    padding: 3px;
    border-radius: initial;
    font-size: 13px;
    font-family: $akzidenz;
    font-weight: bold;
    background-color: $color-navy;
    &:hover {
      background-color: $color-navy;
    }
    &.elc-add-to-bag-button-disabled {
      background-color: $color-medium-gray;
      color: $color-white;
    }
  }
  .elc-add-to-bag-button {
    min-width: 135px;
    white-space: nowrap;
    width: fit-content;
  }
  // clean this cta style on MPPFIND-3943
  .elc-product-cta-wrapper,
  .elc-product-full-cta-wrapper {
    float: $rdirection;
    margin-top: -30px;
    display: inherit;
    margin-#{$ldirection}: 104px;
    z-index: 1;
  }
  .elc-product-cta-wrapper {
    order: 7; // remove on MPPFIND-3943
    margin-top: -35px;
    margin-#{$ldirection}: 97px;
  }
  .elc-product-full-cta-wrapper {
    .elc-product-cta-wrapper {
      margin-top: -33px;
    }
  }
  .elc-rating-component-container {
    width: fit-content;
  }
  .quickview-content-price-cta-wrapper {
    .elc-product-cta-wrapper {
      position: absolute;
      #{$ldirection}: 96.5px;
      bottom: 14px;
      // the next 2 selectors should be removed, needed now for backward compatibility
      .elc-add-to-bag-sku-inventory-status-message {
        position: absolute;
        top: 50px;
        width: 200px;
      }
      .elc-add-to-bag-sku-inventory-status-message-message {
        color: $color-red;
      }
    }
  }
  .elc-shades-dropdown-component {
    & ~ .elc-add-to-bag-sku-inventory-status-message {
      order: 3;
    }
  }
  .elc-add-to-bag-sku-inventory-status-message {
    display: flex;
    order: 5;
    width: 100%;
    margin: 0 0 10px;
  }
  .elc-add-to-bag-sku-inventory-status-message-message {
    font-family: $akzidenz;
    font-size: 15px;
    color: $color-red;
  }
  .quickview-content-price-cta-wrapper {
    .elc-quickview-content-prices-wrapper {
      position: absolute;
      margin: 0;
      padding: 0;
      bottom: -17px;
    }
  }
  .elc-price-formatted {
    color: $color-navy;
    font-family: $akzidenz;
    font-size: 15px;
    font-weight: bold;
  }
  .elc-product-original-price {
    padding-#{$rdirection}: 10px;
  }
  .elc-price-formatted.elc-product-price {
    color: $color-red;
    font-weight: bold;
  }
  .elc-product-price-row-wrapper {
    display: flex;
    height: 20px;
    @media #{$large-up} {
      margin-#{$rdirection}: 16px;
      margin-#{$ldirection}: 0;
    }
  }
  .elc-price-formatted-wrapper {
    align-self: center;
  }
  .elc-product {
    &-rating-wrapper {
      .elc-stars-overlay span {
        margin-#{$rdirection}: 5px;
      }
      .elc-star-icon {
        width: 12px;
        height: 12px;
      }
      .elc-rating-reviews {
        font-size: 15px;
      }
      .elc-reviews-number {
        margin-top: 3px;
        font: 15px/1 $akzidenz;
        color: $color-navy;
      }
    }
  }
  .elc-product-installments-price {
    order: 7;
    display: flex;
    flex-direction: row;
    padding: 0;
    margin-top: 12px;
    &-logo {
      height: 20px;
    }
    &-label,
    &-unavailable-label {
      font-size: 12px;
      padding-#{$ldirection}: 3px;
    }
    &-open-modal {
      border: none;
      background-color: transparent;
      height: auto;
      min-width: auto;
      padding: 0;
      order: 2;
      &:hover {
        background: transparent;
      }
    }
  }
  .elc-quickview-content-prices-wrapper {
    order: 6;
    margin-#{$ldirection}: 0;
  }
}

.elc-product-quick-view-wrapper {
  .elc-right-column-section {
    .elc-shades-dropdown-component {
      & ~ .elc-add-to-bag-sku-inventory-status-message {
        order: unset;
      }
    }
  }
}

// merge with rules above on MPPFIND-3943
.elc-rating {
  .elc-product-quick-view-wrapper .elc-right-column-section & {
    margin: 10px 0;
  }
}
.elc-shade-swatches-wrapper {
  .elc-product-quick-view-wrapper .elc-right-column-section & {
    margin-top: 5px;
  }
}

// remove rule on MPPFIND-3943
.elc-product-quick-view-details-wrapper {
  .elc-product-installments-price {
    margin-top: 20px;
  }
  .elc-shades-dropdown-component {
    order: 3;
  }
  .elc-shade-image {
    margin-top: 1px;
  }
  .elc-product-info-short-description-wrapper {
    margin-top: 8px;
  }
}

.elc-iframe-modal-wrapper {
  button.icon-close {
    #{$rdirection}: 5px;
    background: transparent;
    border: none;
    position: absolute;
    top: 10px;
  }
}
// Breadcrumbs
nav.breadcrumbs {
  background-color: $color-white;
  padding: 10px;
  margin: 0 auto;
  @media #{$medium-up} {
    @include swap_direction(padding, 0 0 0 20px);
  }
  &:first-child {
    margin-top: 10px;
    @media #{$medium-up} {
      margin-top: 50px;
    }
  }
  .breadcrumbs__level {
    color: $color-navy;
    text-decoration: none;
    &:before {
      content: ' /';
    }
    &:first-child:before {
      content: '';
    }
    a {
      font-size: 15px;
      color: $color-navy;
      text-decoration: none;
      &:hover {
        border-bottom: 1px solid $color-navy;
      }
    }
    h1 {
      display: inline-block;
      font-size: 15px;
      letter-spacing: inherit;
      margin: 0;
      padding: 0;
      color: $color-navy;
      font-family: inherit;
    }
  }
}

.elc-product-quick-view-details-wrapper,
.elc-product-brief-wrapper,
.elc-product-brief {
  .elc-product {
    &-rating-wrapper {
      .elc-rating {
        margin: 4px 0 12px;
      }
      .elc-read-reviews,
      .elc-average-rating-text {
        display: none;
      }
      .elc-star-overlay {
        background-color: $color-navy;
        background: url($svg-path + 'el_star.svg') 95% 50% no-repeat;
        -webkit-mask: none;
      }
    }
  }

  .elc-outline-favorite-icon,
  .elc-favorite-button-wrapper {
    display: none;
  }
}

.elc-responsive-modal-mask {
  .elc-product-notify-me-pop-up-wrapper,
  .elc-product-notify-me-confirmation-wrapper {
    background-color: $color-white;
    border: 1px solid $color-medium-gray;
    display: flex;
    flex-direction: column;
    min-height: 230px;
    @media #{$medium-up} {
      display: block;
      width: 544px;
      min-height: 408px;
    }
    .elc-product-notify-me-pop-up-close-button {
      background-color: unset;
      display: flex;
      justify-content: flex-end;
      margin: 16px;
      padding: 0;
      @media #{$medium-up} {
        margin: 20px;
      }
      .elc-product-notify-me-pop-up-close-button-icon {
        background-color: $color-medium-gray;
        height: 28px;
        width: 28px;
      }
    }
    .elc-product-notify-me-content {
      padding: 45px 15px 20px;
      @media #{$medium-up} {
        padding: 50px 48px 0;
      }
      .elc-product-notify-me-title-label {
        font: 28px/30px $font-optimadisplay-light;
        color: $color-navy;
        letter-spacing: 0;
      }
      .elc-product-notify-me-description-label {
        font: 15px/22px $akzidenzBQ;
        color: $color-navy;
        font-weight: 500;
        margin: 10px 0 15px;
        letter-spacing: 1px;
      }
    }
    .elc-product-notify-me-form-wrapper {
      width: 100%;
      display: inline-block;
      margin: 0;
      @media #{$medium-up} {
        width: 300px;
      }
      .elc-input-field,
      .elc-input-error,
      .elc-floating-label-string {
        font-family: $akzidenz;
        font-size: 12px;
        text-transform: uppercase;
        color: $color-medium-gray;
      }
      .elc-input-field {
        height: 40px;
        border-radius: 0;
        border: 1px solid $color-medium-gray;
        padding: 0 14px;
        box-shadow: none;
        color: $color-medium-gray;
      }
      .elc-input-error {
        margin: 7px 0 0;
        padding: 0;
        p {
          text-transform: none;
          color: $color-strong-red;
        }
      }
    }
    .elc-product-notify-me {
      &-notice-wrapper {
        position: relative;
        @media #{$medium-up} {
          position: absolute;
        }
      }
      &-gdpr-notice-label {
        @include swap_direction(margin, 0 7% 0 8%);
        font: 12px/18px $akzidenzBQ;
        color: $color-navy;
        @media #{$medium-up} {
          margin: 10px 5px 5px;
        }
        a {
          text-decoration: underline;
        }
      }
      &-gdpr-read-confirmation {
        font: 12px/18px $akzidenzBQ;
        color: $color-strong-red;
        margin-#{$rdirection}: 10px;
      }
      &-info {
        position: relative;
        bottom: 0;
        @media #{$medium-up} {
          position: absolute;
          bottom: 20px;
        }
        &:hover {
          .elc-product-gdpr-popup {
            visibility: visible;
          }
        }
      }
      &-info-your-data {
        font: 12px/18px $akzidenzBQ;
        padding: 5px;
      }
      &-info-button {
        background: transparent;
        padding: 0;
      }
      &-confirmation-content {
        padding: 35px 0 0;
        margin-bottom: 40px;
        @media #{$medium-up} {
          max-width: 90%;
          margin-bottom: 0;
        }
        &-text {
          font: 15px/22px $akzidenzBQ;
          color: $color-navy;
          font-weight: 500;
        }
      }
      &-confirmation-title {
        font: 28px/30px $font-optimadisplay-light;
        color: $color-navy;
        margin: 0 0 15px;
      }
      &-confirmation-continue-button {
        @include swap_direction(margin, 0 15px 15px 0);
        #{$rdirection}: 0;
        background-color: $color-navy;
        font: 12px/18px $akzidenzBQ;
        height: 40px;
      }
      &-confirmation-close-button {
        background: none;
        &-icon {
          background-color: $color-medium-gray;
          height: 28px;
          width: 28px;
        }
      }
    }
    .elc-ok-icon {
      display: none;
    }
    .elc-checkbox {
      float: #{$ldirection};
    }
    .elc-product-gdpr-popup {
      background-color: $color-dark-gray;
      border-radius: 6px;
      bottom: 35px;
      display: flex;
      margin-bottom: 40px;
      padding: 13px;
      position: absolute;
      visibility: hidden;
      width: 100%;
      @media #{$medium-up} {
        display: block;
        width: 450px;
      }
    }
    .elc-product-gdpr-popup-information {
      font: 15px/21px $akzidenzBQ;
      color: $color-white;
      a {
        text-decoration: underline;
        color: $color-white;
      }
    }
    .elc-product-notify-me-submit-button {
      font: 12px/18px $akzidenzBQ;
      height: 40px;
      background-color: $color-navy;
      border-radius: 0;
      font-weight: bold;
      margin-top: 30px;
      @media #{$medium-up} {
        width: 132px;
        float: #{$rdirection};
        margin-top: 0;
      }
    }
  }
  .elc-product-notify-me-confirmation-wrapper {
    display: block;
  }
}

// Simplified Star Ratings
$ratingSize: 14px;
$ratingWidth: 70px;

.elc-stars-simplified {
  height: $ratingSize;
  position: relative;
  width: $ratingWidth;
  &:before {
    filter: contrast(0);
  }
  &-stars {
    display: block;
    height: $ratingSize;
    position: absolute;
    overflow: hidden;
    width: $ratingWidth;
  }
}

.elc-stars-simplified:before,
.elc-stars-simplified-stars:before {
  background: url($svg-path + 'el_star.svg') repeat-x;
  background-size: $ratingSize;
  content: '';
  display: block;
  height: $ratingSize;
  position: absolute;
  top: 0;
  width: $ratingWidth;
}

.elc-product-shades-grid-wrapper {
  .elc-shade-image {
    &.elc-status-sold-out,
    &.elc-status-temporarily-out-of-stock {
      &:after {
        color: $color-white;
        content: '\2044';
        font-size: 27px;
        line-height: 22px;
        transform: rotate(90deg);
        #{$ldirection}: 1px;
        top: 1px;
        z-index: 10;
        cursor: pointer;
      }
    }
    &-selected {
      &:after {
        // @todo remove important when inline style get's removed from react
        box-shadow: $color-white 0 0 0 2px inset !important;
        #{$ldirection}: 1px;
      }
    }
  }
}

/*===== BADGE STYLES FOR:

   - MPP Grid
   - QuickShop
   - Gnav Search
   - Search Results
   - SPP

 =====*/
.elc-grid-item-product,
.elc-product-image-section,
.elc-product-quick-view-wrapper,
.elc-search-enhanced-grid-wrapper,
.elc-search-results-wrapper {
  // Common Text Badge <p> Element Styles.
  .elc-product-badge {
    font-family: $akzidenz;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 18px;
    margin: 0;
    padding: 0;
    text-align: $ldirection;
    text-transform: uppercase;
    white-space: nowrap;
    word-spacing: 3px;
    z-index: 1;

    body.brand-style-renutriv & {
      color: $color-black;
      font-family: $renutriv-roboto;
    }
    body.brand-style-aerin & {
      color: $color-periwinkle-blue--aerin-v2;
      font-family: $futura_now_reg;
    }
  }
  // Common Image Badge <img> Element Styles.
  .elc-product-image-badge {
    height: 80px;
    max-width: 70px;
    position: absolute;
    width: auto;
    z-index: 10;
  }
  // Common Badge Sizing.
  .elc-slider-arrow-wrapper {
    .elc-product-image-badge {
      @media #{$medium-up} {
        max-width: 68px;
      }
    }
  }
  // Mobile Badge Structure.
  .elc-product-badges-wrapper {
    // Text Badge Wrapper.
    .elc-product-badge-wrapper {
      #{$ldirection}: 15px;
      position: absolute;
      top: 15px;
      user-select: none;
      z-index: 1;
      // When Image Badge Also Appears After Text Badge.
      & + .elc-product-image-badge-wrapper {
        position: absolute;
        top: 48px;
      }
    }
    // Image Badge Wrapper.
    .elc-product-image-badge-wrapper {
      #{$ldirection}: 15px;
      position: absolute;
      top: 15px;
      z-index: 1;
    }
    // This is the badge <img> element.
    .elc-product-image-badge {
      #{$ldirection}: 0;
      top: 0;
    }
  }
  // Desktop Badge Structure.
  .elc-product-image-badge-wrapper {
    // Text Badge Wrapper.
    .elc-product-badge-wrapper {
      @media #{$medium-up} {
        #{$ldirection}: 10px;
        position: absolute;
        top: 10px;
        user-select: none;
      }
      // When Image Badge Also Appears After Text Badge.
      & + .elc-product-image-badge-wrapper {
        @media #{$medium-up} {
          top: 38px;
        }
      }
    }
    // Image Badge Wrapper.
    .elc-product-image-badge-wrapper {
      @media #{$medium-up} {
        #{$ldirection}: 10px;
        position: absolute;
        top: 10px;
        z-index: 1;
      }
    }
    // This is the badge <img> element.
    .elc-product-image-badge {
      @media #{$medium-up} {
        #{$ldirection}: 0;
        top: 0;
      }
    }
  }
}

// Quick View Override
.elc-product-quick-view-wrapper {
  .elc-product-badges-wrapper {
    @media #{$medium-up} {
      .elc-product-badge-wrapper {
        #{$ldirection}: 10px;
        top: 10px;
        & + .elc-product-image-badge-wrapper {
          #{$ldirection}: 10px;
          top: 38px;
        }
      }
      .elc-product-image-badge-wrapper {
        @media #{$medium-up} {
          #{$ldirection}: 10px;
          top: 10px;
        }
      }
    }
  }
}

// Search Results Override
.elc-search-results-wrapper {
  .elc-product-image-badge-wrapper {
    // Text Badge Wrapper.
    $mobile_side_offset: 15px;
    $desktop_side_offset: 10px;
    .elc-product-badge-wrapper {
      #{$ldirection}: $mobile_side_offset;
      position: absolute;
      top: 15px;
      user-select: none;
      @media #{$medium-up} {
        #{$ldirection}: $desktop_side_offset;
        top: 10px;
      }
      // When Image Badge Also Appears After Text Badge.
      & + .elc-product-image-badge-wrapper {
        top: 48px;
        @media #{$medium-up} {
          top: 38px;
        }
      }
    }
    // Image Badge Wrapper.
    .elc-product-image-badge-wrapper {
      #{$ldirection}: $mobile_side_offset;
      position: absolute;
      top: 15px;
      z-index: 1;
      @media #{$medium-up} {
        #{$ldirection}: $desktop_side_offset;
        top: 10px;
      }
    }
  }
}

// SD SPP
.sd-product-spp {
  .elc-product-badge-wrapper {
    .elc-product-image-badge {
      height: 80px;
      max-width: 70px;
      width: auto;
      @media #{$medium-up} {
        height: 103px;
        max-width: 90px;
      }
    }
  }
  &__product-cross-sell {
    // Mobile Badge Structure.
    .elc-product-badges-wrapper {
      // Text Badge Wrapper.
      .elc-product-badge-wrapper {
        #{$ldirection}: 15px;
        top: 10px;
        & + .elc-product-image-badge-wrapper {
          #{$ldirection}: 15px;
          position: absolute;
          top: 38px;
        }
      }
      // Image Badge Wrapper.
      .elc-product-image-badge-wrapper {
        #{$ldirection}: 15px;
        position: absolute;
        top: 15px;
        z-index: 1;
      }
      // This is the badge <img> element.
      .elc-product-image-badge {
        #{$ldirection}: 0;
        top: 0;
      }
    }
    // Desktop Badge Structure.
    .elc-product-image-badge-wrapper {
      // Text Badge Wrapper.
      .elc-product-badge-wrapper {
        @media #{$medium-up} {
          #{$ldirection}: 30px;
          top: 32px;
        }
        // When Image Badge Also Appears After Text Badge.
        & + .elc-product-image-badge-wrapper {
          @media #{$medium-up} {
            top: 55px;
          }
        }
      }
      // Image Badge Alone.
      .elc-product-image-badge-wrapper {
        @media #{$medium-up} {
          #{$ldirection}: 30px;
          position: absolute;
          z-index: 1;
          top: 36px;
        }
      }
    }
    .elc-product-image-badge-wrapper:hover + .elc-product-quick-view-button-wrapper {
      .elc-product-quick-view-button {
        display: block;
      }
    }
  }
}

.elc-product-quick-view-wrapper,
.elc-product-brief-view,
.sd-product-grid,
.sd-product-spp__product-cross-sell {
	.elc-product-zoom-button,
  .elc-product-zoom {
		display: none;
	}
}

.elc-product-zoom {
  display: none;
  .elc-product-full & {
    display: block;
  }
}

.elc-product-quick-view-button-wrapper {
  @media #{$device-ipad-tablet-portrait-landscape} {
    display: none;
  }
}
